[type='range'] {
  -webkit-appearance: none;
  background: transparent;
  margin: 6px 0;
  width: 100%;
  overflow: hidden; }
  [type='range']::-moz-focus-outer {
    border: 0; }
  [type='range']:focus {
    outline: 0; }
  [type='range']::-webkit-slider-runnable-track {
    height: 4px;
    transition: all .2s ease;
    width: 100%;
    cursor: pointer;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2), 0 0 0px rgba(13, 13, 13, 0.2);
    background: #e8ebee;
    border: 0px solid #cfd8dc;
    border-radius: 0px; }
  [type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2), 0 0 0px rgba(13, 13, 13, 0.2);
    background: #39dbfd;
    border: 0px solid #eceff1;
    border-radius: 6px;
    cursor: default;
    height: 12px;
    width: 12px;
    -webkit-appearance: none;
    margin-top: -4px;
    box-shadow: -100vw 0 0 100vw #39dbfd; }
  [type='range']::-moz-range-track {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2), 0 0 0px rgba(13, 13, 13, 0.2);
    height: 4px;
    transition: all .2s ease;
    width: 100%;
    cursor: pointer;
    background: #e8ebee;
    border: 0px solid #cfd8dc;
    border-radius: 0px;
    height: 2px; }
  [type='range']::-moz-range-progress {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2), 0 0 0px rgba(13, 13, 13, 0.2);
    height: 4px;
    transition: all .2s ease;
    width: 100%;
    cursor: pointer;
    background: #39dbfd;
    border: 0px solid #cfd8dc;
    border-radius: 0px;
    height: 2px; }
  [type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2), 0 0 0px rgba(13, 13, 13, 0.2);
    background: #39dbfd;
    border: 0px solid #eceff1;
    border-radius: 6px;
    cursor: default;
    height: 8px;
    width: 8px; }
  [type='range']::-ms-track {
    height: 4px;
    transition: all .2s ease;
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent; }
  [type='range']::-ms-fill-lower {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2), 0 0 0px rgba(13, 13, 13, 0.2);
    background: #2fd9fd;
    border: 0px solid #cfd8dc;
    border-radius: 0px; }
  [type='range']::-ms-fill-upper {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2), 0 0 0px rgba(13, 13, 13, 0.2);
    background: #e8ebee;
    border: 0px solid #cfd8dc;
    border-radius: 0px; }
  [type='range']::-ms-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2), 0 0 0px rgba(13, 13, 13, 0.2);
    background: #39dbfd;
    border: 0px solid #eceff1;
    border-radius: 6px;
    cursor: default;
    height: 8px;
    width: 8px;
    margin-top: 1px; }
  [type='range']:disabled::-webkit-slider-thumb {
    cursor: not-allowed; }
  [type='range']:disabled::-moz-range-thumb {
    cursor: not-allowed; }
  [type='range']:disabled::-ms-thumb {
    cursor: not-allowed; }
  [type='range']:disabled::-webkit-slider-runnable-track {
    cursor: not-allowed; }
  [type='range']:disabled::-ms-fill-lower {
    cursor: not-allowed; }
  [type='range']:disabled::-ms-fill-upper {
    cursor: not-allowed; }

.controls-seek-bar {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin-top: -15px; }
