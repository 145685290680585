$track-color:    #e8ebee !default;
$progress-color: #39dbfd !default;
$thumb-color: $progress-color !default;

$thumb-radius: 6px !default;
$thumb-height: $thumb-radius*2 !default;
$thumb-width: $thumb-height !default;
$thumb-shadow-size: 0px !default;
$thumb-shadow-blur: 0px !default;
$thumb-shadow-color: rgba(0, 0, 0, .2) !default;
$thumb-border-width: 0px !default;
$thumb-border-color: #eceff1 !default;

$track-height: 4px !default;
$track-width: 100% !default;
$track-shadow-size: 0px !default;
$track-shadow-blur: 0px !default;
$track-shadow-color: rgba(0, 0, 0, .2) !default;
$track-border-width: 0px !default;
$track-border-color: #cfd8dc !default;

$track-radius: 0px !default;
$contrast: 2% !default;

$ie-bottom-track-color: darken($progress-color, $contrast) !default;

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color, 0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin track {
  height: $track-height;
  transition: all .2s ease;
  width: $track-width;
  cursor: pointer;

}

@mixin thumb($adjustment: 0) {
  @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  background: $thumb-color;
  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-radius;
  cursor: default;
  height: $thumb-height + $adjustment;
  width: $thumb-width + $adjustment;
}

@mixin disabled {
  cursor: not-allowed;
}

[type='range'] {
	-webkit-appearance: none;
	background: transparent;
	margin: $thumb-height / 2 0;
	width: $track-width;
	overflow: hidden;

  &::-moz-focus-outer {
    border: 0;
  }

  &:hover {
    // &::-webkit-slider-runnable-track {
    //   background: lighten($track-color, $contrast); 
    // }

    // &::-webkit-slider-thumb {
    //   background: lighten($thumb-color, $contrast);
    // }

    // &::-ms-fill-upper {
    //   background: lighten($track-color, $contrast);
    // }
  }

  &:focus {
    outline: 0;
  }

  &::-webkit-slider-runnable-track {
    @include track;
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;    
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: ((-$track-border-width * 2 + $track-height) / 2 - $thumb-height / 2);  
    box-shadow: -100vw 0 0 100vw $progress-color;    
  }

  &::-moz-range-track {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    @include track;
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
    height: $track-height / 2;
  }

  &::-moz-range-progress {
  	@include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    @include track;
  	background: $progress-color; 
  	border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
    height: $track-height / 2;
  }

  &::-moz-range-thumb {
    @include thumb(-4);
  }

  &::-ms-track {
    @include track;
    background: transparent;
    border-color: transparent;
    border-width: ($thumb-height / 2) 0;
    color: transparent;
  }

  // PROGRESS
  &::-ms-fill-lower {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $ie-bottom-track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }
  // TRACK
  &::-ms-fill-upper {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: ($track-radius * 2);
  }

  &::-ms-thumb {
    @include thumb(-4);
    margin-top: $track-height / 4;
  }

  &:disabled {
    &::-webkit-slider-thumb {
      @include disabled;
    }

    &::-moz-range-thumb {
      @include disabled;
    }

    &::-ms-thumb {
      @include disabled;
    }

    &::-webkit-slider-runnable-track {
      @include disabled;
    }

    &::-ms-fill-lower {
      @include disabled;
    }

    &::-ms-fill-upper {
      @include disabled;
    }
  }
}

// FIX it to the top
.controls-seek-bar{
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	margin-top: -15px;
}