$theme-color: #39dbfd !default;

$main-color   : black;
$second-color : #d4d4d4 !default;

$main-bttn-size : 50px;

$cards-container-size: 325px;

.uploads-container{
	
	.menu{
		
		margin: 0px 5%;

		.tag {
			cursor: pointer;
			padding: 15px;
			color: $second-color;

			.active{
				color: $main-color;
				border-bottom: 2px solid $main-color;
				padding: 0px 5px 10px 5px;				
			}
		}
	}

	.pag-buttons{
		position: absolute;
		
		top: 15px; right: 5%;

		font-size: 1.25em;
		color: $theme-color;

		& > div{
			cursor: pointer;
		}
	}

}

.cards-row{
	height: $cards-container-size;
	overflow-x: scroll;
	padding-bottom: 15px;
}

.playlist-card{
	flex-shrink: 0;
	width: 18rem;
	height: 100%;
    
    margin-left: 25px;

  	overflow: hidden;

  	background: white;
  	box-shadow: 5px 5px 10px rgba(127, 137, 161, 0.3);
  	border-radius: 10px;
	
	.image{
		// background-repeat: no-repeat;
		// background-size: cover;
		height: 65%;
		overflow: hidden;
		
		img{
			max-width: 100%;
		    height: auto;
		}

	}

	.card-body{
		height: 35%;
	}

	.title{ color: black; }
	.subtitle{ 
		color: $second-color; 
		font-size: 0.8em;
	}

	.main-button{
		position: absolute;
		cursor: pointer;

		z-index:5;

		top   : -$main-bttn-size/2; //Mitad del boton, ver dropzone.scss
		right : 15px;
		
		font-size: 0.85em;
		width:  $main-bttn-size;
		height: $main-bttn-size;
		border-radius: 50%;

		color: white;

		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
		background-color: white; //Obtener de la imagen??
		transition: all 500ms ease-in-out;
	}
}

::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.new-playlist-button{
	position: absolute;
	top: 0; right: 5%;
	color: black;
	padding: 15px;
	font-size: 2em;
	cursor: pointer;
}

.empty-playlists{
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	
	color: $second-color;
	height: $cards-container-size + 25px;
	
	h1{ 
		margin: 0 10%; 
		opacity: 0.5;
	}

}

