$h-color: rgba(0,0,0,0.85);;
$label-color-inactive: rgba(0,0,0,0.45); //#8F8F8F
$root-background: linear-gradient( to bottom right, rgb(167,187,178), rgb(95,183,227));

$label-color-active:  $h-color;
$input-color : $h-color;

$underline-color: $label-color-inactive;
$underline-color-active: $h-color;

.notification-error{
  background-color: rgba(50,50,50,1) !important;
}

.inactive_account{
  background-image: $root-background;
  color: $h-color;
  text-align: center;
  position: absolute;

  .position-absolute{
    margin: 0px 25px;
  }

  .beta_info{
    color: $label-color-inactive;
  }

  .beta_contact{
    position: relative;
    top: 30px; 
    color: $label-color-inactive;

    a{ color: $h-color; }
  }

  .code-submit{
    position: absolute;
    bottom: 65px;
    right:  0;
    font-size: 2em;
    opacity: 0;
  
    transition: all 1s ease;

    &.enabled{
      opacity: 1;
      cursor: pointer;
    }

    @media screen and (max-width: 568px) {
      bottom: 85px !important; 
    }

  }
  
  textarea:focus,
  input:focus {
    outline: 0;
  }

  /* Question */

  input.question,
  textarea.question {
    color: $input-color;
    font-size: 48px;
    font-weight: 300;
    border-radius: 2px;
    margin: 0;
    border: none;
    width: 80%;
    background: rgba(0, 0, 0, 0);
    transition: padding-top 0.2s ease, margin-top 0.2s ease;
    overflow-x: hidden; /* Hack to make "rows" attribute apply in Firefox. */
  }

  /* Underline and Placeholder */
  input.question + label,
  textarea.question + label {
    display: block;
    position: relative;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    width: 10%;
    border-top: 1px solid $underline-color;
    -webkit-transition: width 0.4s ease;
    transition: width 0.4s ease;
    height: 0px;
  }

  input.question:focus + label,
  textarea.question:focus + label {
    width: 80%;
  }

  input.question:focus,
  input.question:valid {
    padding-top: 35px;
  }

  textarea.question:valid,
  textarea.question:focus {
    margin-top: 35px;
  }

  input.question:focus + label > span,
  input.question:valid + label > span {
    top: -100px;
    font-size: 22px;
    color: $label-color-active;
  }

  input.question:valid + label,
  textarea.question:valid + label {
    border-color: $underline-color-active;
  }

  input.question:invalid,
  textarea.question:invalid {
    box-shadow: none;
  }

  input.question + label > span,
  textarea.question + label > span {
    font-weight: 300;
    margin: 0;
    position: absolute;
    color: $label-color-inactive;
    font-size: 48px;
    top: -66px;
    left: 0px;
    z-index: 999;
    -webkit-transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
    transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease;
  }


  .logout-bttn{
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 2em;
    cursor: pointer;
  }

}