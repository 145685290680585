$title-color: black !default;
$subtitle-color: #d4d4d4 !default;
$toggle-color: #39dbfd !default;
$break-md: 767px;
$entry-height:80px;

.track-entry {    
    font-size: 1.1em;
    width: 100%;
	height: $entry-height;   
	
	color: $subtitle-color;

	display: flex;
	justify-content: left;
	align-items: center ;
	
	&:hover,
	&.active {
		background-color: white ;
		
		.track,
		.playlist-index,
		.play-button,
		.artists{
			color: $toggle-color !important;
		}
	}    

	
	.play-button{
		cursor: pointer;
		font-size: 2em;
		border-radius: 100%;
	}
	
	.heart-button{
		cursor: pointer;
		font-size: 1.5em;
		border-radius: 100%;	
		
		&.active{
			color: $toggle-color;
		}
	}
	
	.song_info{
		padding-top: 15px;
		height: $entry-height;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;

		overflow-x: scroll;
		margin-right: 15px;
		
		.track{
			color: $title-color;
		}

		.artists{
			font-size: 0.8em;
		}

		.artists,
		.track{
			display: inline-block;
			white-space: nowrap;
		}
	}
}

