$fab-size: 60px;

// $dropzone-color: hsla(14, 92%, 70%, 1);
// $dropzone-second-color: hsla(14, 57%, 85%, 1);

$dropzone-color: hsla(338, 61%, 60%, 1);
$dropzone-second-color: hsla(335, 57%, 85%, 1);

$dropzone-bg: rgb(250,250,250);

.dropzone-container{
	height: 100%;
	width : 100%;
	background-color: $dropzone-bg;
	color: $dropzone-color;
	
	background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

	.upload-icon{
		color: $dropzone-second-color;
		font-size: 8em;
	}

	.title{
		color: $dropzone-color;
		font-size: 2em;
	}

	.subtitle{
		color: $dropzone-second-color;
	}

	overflow: hidden;
}
            
.fab {
  position: absolute;
  bottom: 25px; 

  cursor: pointer;
  
  left: 0; right: 0;
  margin-left:  auto;
  margin-right: auto;

  z-index:5;

  font-size: $fab-size/2.25;

  width:  $fab-size;
  height: $fab-size;
  border-radius: 50%;

  // margin: 0px 25px 25px 0px;

  background-color: $dropzone-color;
  color: $dropzone-second-color;
  
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  
  transition: all 1000ms ease-in-out;

  &.hidden{
  	bottom: -$fab-size;
  }

}