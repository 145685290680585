$border-color: rgb(240,240,240) !default;
$border-size:  2px !default;
$break-md: 767px !default;

$home-upload-height: 300px;

body {
  background: rgb(250,250,250);
  color: #444;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;

  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

a {
  color: #1bb1dc;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #0a98c0;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1,h2,h3,h4,h5,h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}

html,
body,
#root,
#root > div{
  height: 100%;
}

.max-width-100{
  max-width: 100%;
}


.bxl{ border-left:solid $border-color $border-size; }
.bxr{ border-right:solid $border-color $border-size; }
.bxt{ border-top:solid $border-color $border-size; }
.bxb{ border-bottom:solid $border-color $border-size; }

.home-uploads-container{
  display: flex;
  flex-wrap: nowrap;
  height: $home-upload-height;
  margin:0;
}

@media screen and (max-width: $break-md) {
  .home-uploads-container{
    flex-wrap: wrap;
    height: initial;

    & > div{
      height: $home-upload-height !important;
    }

    & > div.hidden{
      height: 0 !important;
    }
    
  }
}


.hidden{ 
  opacity: 0; 
  cursor: initial;
}

.animated{
  transition: all 1000ms ease;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.login-container{
  overflow-y: hidden;
  height: 100vh;
  font-size: 1.5em;
  background-image: linear-gradient( to bottom, rgb(255,255,255), rgb(220,220,220));
  
  .bg-img{
    position: absolute;
    max-height: 100vh;
    z-index: 0;
    opacity: 0.25;
    margin: auto;
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    filter: grayscale(100%);
    // filter: hue-rotate(-120deg) ;
  }
  
  .logo{
    width: 600px;
    height: auto;
  }

  .spotify-button{
    background: url("/assets/login_spotify.png") no-repeat center;
    background-size: contain;
    cursor: pointer;
    width:  250px;
    height: 53px; 
    position: relative;
    bottom: 50px;
  }

  .central-wrapper{
    text-align: center;

    p{
      position: relative;
      bottom: 55px;
    }
  }

  @media screen and (max-width: 768px) {    
    font-size: 1.2em;
    overflow-x: hidden;
    
    .central-wrapper{
      position: relative;
      bottom: 50px;
    }

    .logo{
      width: 350px !important;
    }
    
    .bg-img{
      max-width:100%;
      // max-height: 100%;
      transform: rotate(90deg); 
      // background: red;
      // display: none;
    }

    p{
      bottom: 25px !important;
    }

    .spotify-button{
      width: 350px;
      bottom: -25px;
    }
  }
}
