$frames-width : 300px;
$frames-height: 362px;

.loader-container{
	width: 100%;
	height: 100%;
	
	z-index: 99999;
	position: absolute;
	background: rgba(200,200,200,0.8);
	
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	.loader{
		position: relative;
		bottom: 50px;
		width: $frames-width;
		height: $frames-height;
		
		span{
			color: rgba(142,69,199,0.65);
			font-size: 2em;
		}
	}
}

.blinking{
    animation: blinkingText 2.5s infinite;
}

@keyframes blinkingText{
    0%{     opacity: 0;    }
    50%{    opacity: 1;    }
    100%{   opacity: 0;    }
}