$title-color: black !default;
$subtitle-color: #d4d4d4 !default;
$toggle-color: #39dbfd !default;
$break-md: 767px !default;
$player-height:80px;
$player-color: white !default; // #f9fbfb

// Must use this class on the div:has(.player-container)
.with-player{
	padding-bottom: $player-height;
}

.player-container{
	width: 100%;
	height: $player-height;
	left:0;
	bottom: 0;
	position: fixed;
	color: $title-color;
	z-index: 99;
	opacity: 1;
	background-color: $player-color;
}

/*Position|Duration*/
.song-status{
	color: $subtitle-color;
	position: absolute;
	top: 0; right: 0;
	padding: 5px;
	font-size: 15px;
}

.controls{
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center ;
	
	div{
		cursor: pointer;
		border-radius: 50%;
		margin: 0 15px;

		// background-color: red;
	}

	.toggle-song{
		font-size: 3em;
		color: $toggle-color;
	}

	.active{
		color: $toggle-color;	
	}
}

.info {
	position: absolute;
	top: 0; left: 0;

	width: 33%;
	height: 100%;
	display: flex;

	img{
		padding: 10px;
		width: 80px;
	}

	div{
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	
	.song_info{
		
		overflow-x: scroll;
		overflow-y: hidden;
		padding-top: 15px; 

		.artists{
			font-size: 0.85em;
			color: $subtitle-color;
		}
		
		.artists,
		.track{
			display: inline-block;
			white-space: nowrap;
		}
	}
	
	@media screen and (max-width: $break-md) {
		width: 25%;
    	font-size: 0.9em;

    	.song_info{
    		font-size: 1.1em;
    	}
  	}
  	
}